import React, { useState, useRef, useEffect } from "react";
import { Table, Input, Space, Button, Tag } from 'antd';
import {
  SearchOutlined, PlusOutlined
} from '@ant-design/icons';
import tickets from "../../dataLayer/tickets";
import { AddTicket } from "./addTicket";
import { Link } from "react-router-dom";
import { formatDate } from "../../utilities/dateFunctions";
import { getSites, getStatus } from "../../utilities/ticketLookups";
import users from "../../dataLayer/users";

const TicketManagement = () => {

  const [data, setData] = useState([]);
  const [filterPrefData, setFilterPrefData] = useState({ status: null, site: null });

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [refreshData, setRefreshData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  useEffect(async () => {

    const filterPref = await users.getUserPref('ticketTable_Filters');
    if (filterPref.length > 0) {
      setFilterPrefData(JSON.parse(filterPref[0].prefValue));
    }

    const result = await tickets.getAllTickets();
    setData(result);
  }, [refreshData]);

  const handleChange = async (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilterPrefData(filters);

    await users.updateUserPref('ticketTable_Filters', { value: JSON.stringify(filters) });
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn(dataIndex);
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //   setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        text
      ) : (
        text
      ),
  });

  var columns = () => {
    return [
      {
        title: 'Ticket ID',
        dataIndex: 'ticket_id',
        key: 'ticket_id',
        width: '200px',
        sorter: (a, b) => a.ticket_id.localeCompare(b.ticket_id),
        sortOrder: sortedInfo.columnKey === 'ticket_id' ? sortedInfo.order : null,
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
        ...getColumnSearchProps('ticket_id')
      },
      {
        title: 'Issue Summary',
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => a.title.localeCompare(b.title),
        sortOrder: sortedInfo.columnKey === 'title' ? sortedInfo.order : null,
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
        ...getColumnSearchProps('title'),
        render: (_, record) => (
          <Link to={"/ticket/" + record.ticket_id}>{record.title}</Link>
        )
      },
      {
        title: 'Site',
        dataIndex: 'site',
        key: 'site',
        width: '200px',
        sorter: (a, b) => a.site.localeCompare(b.site),
        sortOrder: sortedInfo.columnKey === 'site' ? sortedInfo.order : null,
        sortDirections: ['descend', 'ascend'],
        filteredValue: filterPrefData.status,
        filteredValue: filterPrefData.site,
        ellipsis: true,
        filters: getSites(),
        onFilter: (value, record) => record.site === value,
        render: (_, record) => {
          var color = '';
          if (record.site === 'Norton Sub Hamdon') color = 'green';
          if (record.site === 'West Chinnock') color = 'blue';

          return (
            <Tag color={color}>{record.site}</Tag>
          )
        }
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        width: '200px',
        sorter: (a, b) => a.location.localeCompare(b.location),
        sortOrder: sortedInfo.columnKey === 'location' ? sortedInfo.order : null,
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
        ...getColumnSearchProps('location')
      },
      {
        title: 'Created By',
        dataIndex: 'created_by',
        key: 'created_by',
        sorter: (a, b) => a.created_by.localeCompare(b.created_by),
        sortOrder: sortedInfo.columnKey === 'created_by' ? sortedInfo.order : null,
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
        ...getColumnSearchProps('created_by')
      },
      {
        title: 'Date Added',
        dataIndex: 'date_added',
        key: 'date_added',
        width: '160px',
        sorter: (a, b) => a.date_added.localeCompare(b.date_added),
        sortOrder: sortedInfo.columnKey === 'date_added' ? sortedInfo.order : null,
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
        render: (_, record) => (
          formatDate(record.date_added)
        )
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '150px',
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
        sortDirections: ['descend', 'ascend'],
        filters: getStatus(),
        filteredValue: filterPrefData.status,
        onFilter: (value, record) => record.status === value,
        render: (_, record) => {
          var color = '';
          if (record.status === 'New Ticket') color = 'geekblue';
          if (record.status === 'In Progress') color = 'yellow';
          if (record.status === 'Awaiting External') color = 'orange';
          if (record.status === 'Completed') color = 'green';

          return (
            <Tag color={color}>{record.status}</Tag>
          )
        }
      }
    ]
  };

  const showAddModal = () => {
    setModalData([]);
    setShowModal(!showModal);
  }

  const tableOnChange = (pagination, filters, sorter) => {
    console.log(filters)
    console.log(sorter)

  }

  return (
    <div class="contentHolder">
      <Button onClick={showAddModal}><PlusOutlined /> Add New</Button>
      <Table dataSource={data} columns={columns()} onChange={handleChange} rowKey='ticket_id'  scroll={{
      x: 1300,
    }} expandable={{
        expandedRowRender: (record) => (
          <p
            style={{
              margin: 0,
            }}
          >
            <h3>Description</h3>
            {record.description}
          </p>
        ),
        rowExpandable: (record) => record.description.length !== 0,
      }} />
      <AddTicket isModalVisible={showModal} setShowModal={setShowModal} modalData={modalData} refreshDataProp={refreshData} refreshDataSet={setRefreshData} />
    </div>
  );
};

export { TicketManagement };
