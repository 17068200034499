import React from "react";
import { Button, Form, Input, Checkbox, Row, Col, message } from "antd";
import loginController from "../dataLayer/login";


export default function Login({ setToken }) {
  const [form] = Form.useForm();

  const vaildateLogin = async (values) => {
    // alert(values.username)

    var loginResult = await loginController.loginUser(values.username, values.password);

    if (loginResult.status === 200) {
      localStorage.setItem('jath', loginResult.headers['jath']);
      localStorage.setItem('un', loginResult.headers['un']);
      localStorage.setItem('ia', loginResult.headers['ia']);

      setToken(loginResult.headers['jath']);
    }
    else {
      message.error(
        `Login Failed, please check credentials and try again`
      );
    }
  };

  const getColProps = () => ({
    xs:1, 
    sm:1, 
    md:2, 
    lg:5, 
    xl:7, 
    xxl:7
  })

  const getColVontentProps = () => ({
    xs:21, 
    sm:21, 
    md:20, 
    lg:14, 
    xl:10, 
    xxl:10
  })

  return (
    <div>
      <Row>
        <Col {...getColProps()}/>
        <Col {...getColVontentProps()} className="contentHolder"><h1>Login</h1></Col>
        <Col {...getColProps()} />
      </Row>
      <Row>
        <Col {...getColProps()} />
        <Col {...getColVontentProps()} className="contentHolder">
          <Form
            name="basic"
            form={form}
            layout={'vertical'}
            initialValues={{
              remember: true,
            }}
            onFinish={vaildateLogin}
            //  onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password/>
            </Form.Item>

            <Form.Item
            >
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col {...getColProps()} />
      </Row>
    </div>
  );
};
