import React from "react";
import { Route, Routes } from "react-router-dom";

import { UserManagement } from '../pages/users/userManagement';
import { Profile } from '../pages/profile';
import { TicketManagement } from "../pages/tickets/ticketManagement";
import { TicketDetails } from "../pages/tickets/ticketDetails";

const NavRoutes = () => {

    return (
        <Routes>
         <Route exact path="/" element={<TicketManagement/>} />
         <Route exact path="/userManagement" element={<UserManagement/>} />
         <Route exact path="/profile" element={<Profile/>} />
         <Route exact path="/ticket/:id" element={<TicketDetails/>} />

        </Routes>
      );
  };
  
  export {NavRoutes};

