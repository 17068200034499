import React from 'react';
import axios from 'axios';

const apiUrl = 'http://tanavarduratan.hopto.org:8000/';

const getAllTickets = async() => {
    const response = await axios({
        method: 'get',
        url: apiUrl + 'api/v1/GetTickets'
    });

    return response.data;
}

const getTicketDetails = async(id) => {
    const response = await axios({
        method: 'get',
        url: apiUrl + 'api/v1/GetTicket/' + id
    });

    return response.data;
}

const AddTicket = async(values) => {
    const response = await axios({
        method: 'post',
        url: apiUrl + 'api/v1/AddTickets',
        data: values
    });

    return response.data;
}

const UpdateTicket = async(values, id) => {
    const response = await axios({
        method: 'post',
        url: apiUrl + 'api/v1/UpdateTickets/' + id,
        data: values
    });

    return response.data;
}
const AddTicketAction = async(values, id) => {
    const response = await axios({
        method: 'post',
        url: apiUrl + 'api/v1/AddTicketsAction/' + id,
        data: values
    });

    return response.data;
}

const getTicketActions = async(id) => {
    const response = await axios({
        method: 'get',
        url: apiUrl + 'api/v1/GetTicketActions/' + id,
    });

    return response.data;
}

export default {getAllTickets, AddTicket, getTicketDetails, AddTicketAction, getTicketActions, UpdateTicket}