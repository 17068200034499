import React from 'react';
import axios from 'axios';

const apiUrl = 'http://tanavarduratan.hopto.org:8000/';

const loginUser = async(user, pass) => {
    const response = await axios({
        method: 'post',
        url: apiUrl + 'login',
        data: {user: user, password: pass}
    });
    return response;
}

export default { loginUser }