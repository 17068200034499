import React, { useState, useEffect, useRef }  from "react";
import users from "../../dataLayer/users";
import { Table, message, Input, Button, Space, Modal  } from 'antd';
import {
  DeleteOutlined, SearchOutlined, UserAddOutlined, ExclamationCircleOutlined, ReloadOutlined, EditOutlined 
} from '@ant-design/icons';
import {AddUser} from "./addUser";

const { confirm } = Modal;

const UserManagement = () => {

  const [data, setData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);

  const deleteUser = async(record) => {

    confirm({
      title: 'Are you sure you want to delete this user?',
      icon: <ExclamationCircleOutlined />,
  
      async onOk() {
        await users.deleteUser(record.user_id);
        message.success('User ' + record.username + ' deleted');
        refreshGrid();
      },
  
      onCancel() {
        console.log('Cancel');
      },
    });
   
  }

  const editUser = async(record) => {
    setModalData(record);
    setShowModal(true);
  }

  const showAddModal = () => {
    setModalData([]);
    setShowModal(!showModal);
  }

  const refreshGrid = async() => {
    setRefreshData(!refreshData);
  }

  useEffect(async () => {
    const result = await users.getAllUsers();

    setData(result);
  }, [refreshData]);


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn(dataIndex);
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
     //   setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
       text
      ) : (
        text
      ),
  });


  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
      sortOrder: sortedInfo.columnKey === 'username' ? sortedInfo.order : null,
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      ...getColumnSearchProps('username')
    },
    {
      title: 'Email',
      dataIndex: 'email_address',
      key: 'email_address',
      sorter: (a, b) => a.email_address.localeCompare(b.email_address),
      sortOrder: sortedInfo.columnKey === 'email_address' ? sortedInfo.order : null,
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      ...getColumnSearchProps('email_address')
    },
    {
      title: 'Forename',
      dataIndex: 'forename',
      key: 'forename',
      sorter: (a, b) => a.email_address.localeCompare(b.email_address),
      sortOrder: sortedInfo.columnKey === 'forename' ? sortedInfo.order : null,
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      ...getColumnSearchProps('forename')
    },
    {
      title: 'Surname',
      dataIndex: 'surname',
      key: 'surname',
      sorter: (a, b) => a.email_address.localeCompare(b.email_address),
      sortOrder: sortedInfo.columnKey === 'surname' ? sortedInfo.order : null,
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      ...getColumnSearchProps('surname')
    },
    {
      title: 'Actions',
      key: 'action',
      width: '100px',
      render: (_, record) => (
        <div>
        <Space size={"small"}>
        {(localStorage.getItem('ia') === '1' && record.username !== 'admin') && <DeleteOutlined  onClick={() => {deleteUser(record)}}/>}
        <EditOutlined onClick={() => {editUser(record)}}/>
        </Space>
        </div>
      )
    }
  ];

    return (
      <div class="contentHolder">
        <Space>
          <Button onClick={showAddModal} disabled={localStorage.getItem('ia') !== '1'}><UserAddOutlined/>Add User</Button> <Space/>
          <Button onClick={refreshGrid}><ReloadOutlined/> Refresh</Button>
        </Space>
        <Table dataSource={data} columns={columns} onChange={handleChange}  scroll={{x: 1300}}/>
        <AddUser isModalVisible={showModal} setShowModal={setShowModal} modalData={modalData} refreshDataProp={refreshData} refreshDataSet={setRefreshData}/>
      </div>
      );
  };

  export {UserManagement};