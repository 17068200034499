import React from "react";

const Profile = () => {

    return (
      <div>
        This is a user Profile page
      </div>
      );
  };

  export {Profile};