import React, { useState, useRef, useEffect } from "react";
import {
  DatePicker, Input, Space, Button, Timeline, PageHeader, Form, Select,
  Row, Col, Collapse
} from 'antd';
import {
  SearchOutlined, PlusOutlined
} from '@ant-design/icons';
import tickets from "../../dataLayer/tickets";
import { createRoutesFromChildren, Link, useParams } from "react-router-dom";
import { formatDate } from "../../utilities/dateFunctions";
import { getSites, getStatus } from "../../utilities/ticketLookups";
const { Panel } = Collapse;

const { Option } = Select;

const TicketDetails = () => {
  let { id } = useParams();
  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [timeLinedata, setTimeLineData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [action_dateValue, setAction_dateValue] = useState('');




  const GetTimeLineItems = () => {
    return (
      <Timeline mode={'left'}>
        {timeLinedata.map(i => (
          <Timeline.Item label={formatDate(i.action_datetime)}>{i.action_text}</Timeline.Item>
        ))}
      </Timeline>

    )
  }

  useEffect(async () => {
    var results = await tickets.getTicketDetails(id);
    setData(results);

    var actionResults = await tickets.getTicketActions(id);
    setTimeLineData(actionResults);
  }, [refreshData]);

  const updateTicket = async (values) => {
    console.log(values)
    await tickets.UpdateTicket(values, id);
    setRefreshData(!refreshData);
  }

  const updateActions = async (values) => {
    const actionData = { action_datetime: values.action_datetime._d.toJSON(), action_text: values.action_text };
    await tickets.AddTicketAction(actionData, id);
    form.setFieldsValue({ action_text: '', action_datetime: '' })
    setRefreshData(!refreshData);
  }

  return (
    <div>
      <Space direction="vertical" style={{ display: "flex" }}>

        <PageHeader title={data.length == 0 ? '' : data[0].title} subTitle={id} className="contentHolder" />
        <Collapse defaultActiveKey={['2']}>
          <Panel header="Ticket Details" key="1">

            <div class="contentHolder">
              <Form
                name="formDetails"
                form={form}
                layout={'vertical'}
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                onFinish={updateTicket}
                autoComplete="off"
                fields={[
                  { name: "title", value: data.length == 0 ? '' : data[0].title },
                  { name: "description", value: data.length == 0 ? '' : data[0].description },
                  { name: "site", value: data.length == 0 ? '' : data[0].site },
                  { name: "location", value: data.length == 0 ? '' : data[0].location }

                ]}
              >
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      label="Issue Summary"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: 'Please input a title!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Site"
                      name="site"
                      rules={[
                        {
                          required: true,
                          message: 'Please input site!',
                        },
                      ]}
                    >
                      <Select>
                        {getSites().map(s => <Option value={s.value}>{s.text}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Location"
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: 'Please input location!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      label="Description of issue"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: 'Please input a description!',
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </Col>
                </Row>
                <Button type="primary" htmlType="submit">Update Details</Button>
              </Form>

            </div>
          </Panel>
          <Panel header="Action Details" key="2">
            <div class="contentHolder">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form
                    name="formActions"
                    form={form}
                    layout={'vertical'}
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    onFinish={updateTicket}
                    autoComplete="off"
                    fields={[
                      { name: "status", value: data.length == 0 ? '' : data[0].status },

                    ]}
                  >
                    <Form.Item
                      label="Status"
                      name="status"
                      rules={[
                        {
                          required: true,
                          message: 'Please input status!',
                        },
                      ]}
                    >
                      <Select>
                        {getStatus().map(s => <Option value={s.value}>{s.text}</Option>)}
                      </Select>

                    </Form.Item>

                    <Button type="primary" htmlType="submit">Save Action</Button>
                  </Form>
                  <Form onFinish={updateActions}>
                    <Form.Item
                      label="Action Date Time"
                      name="action_datetime"
                      rules={[
                        {
                          required: true,
                          message: 'Please Action Date and Time',
                        },
                      ]}
                    >
                      <DatePicker showTime={true} />
                    </Form.Item>
                    <Form.Item
                      label="Action Summary"
                      name="action_text"
                      rules={[
                        {
                          required: true,
                          message: 'Please Action Summary',
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>

                    <Button type="primary" htmlType="submit" >Add Action</Button>
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{ paddingTop: '10px' }}>
                  <GetTimeLineItems />
                </Col>
              </Row>
            </div>

          </Panel>
        </Collapse>
      </Space >

    </div >
  );
};

export { TicketDetails };
