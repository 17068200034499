import React from "react";
import users from "../../dataLayer/users";
import { message, Input, Form, Modal, Switch } from 'antd';

export const AddUser = (props) => {
    const [form] = Form.useForm();

    const handleCancel = () => {
        props.setShowModal(false);
    }

    const addUser = async (values) => {
        
        values.email_address = (values.email_address === undefined ? '' : values.email_address);

        if(props.modalData.username === undefined || props.modalData.username === '')
        {
            await users.addUser(values);
        }
        else {
            await users.updateUser(values, props.modalData.user_id);
        }
        message.success('User Created');
        props.refreshDataSet(!props.refreshDataProp);
        props.setShowModal(false);
    }

    return (
        <Modal title="Create new user" visible={props.isModalVisible} onOk={form.submit} onCancel={handleCancel} okText='Save'>
            <Form
                name="basic"
                form={form}
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                onFinish={addUser}
                //  onFinishFailed={onFinishFailed}
                autoComplete="off"
                fields={[
                    {name:"username", value: props.modalData.username},
                    {name:"email_address", value: props.modalData.email_address},
                    {name:"forename", value: props.modalData.forename},
                    {name:"surname", value: props.modalData.surname},
                    {name:"password", value: ''},

                ]}
            >
                  <Form.Item
                    label="User name"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input disabled={props.modalData.username !== '' && props.modalData.username !== undefined} />
                </Form.Item>
                <Form.Item
                    label="Email Address"
                    name="email_address"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password autoComplete="new-password"/>
                </Form.Item>
                <Form.Item
                    label="Forename"
                    name="forename"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your forename!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Surname"
                    name="surname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your surname!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Is Admin"
                    name="isAdmin"
                >
                    <Switch disabled={localStorage.getItem('ia') !== '1'}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default { AddUser }