import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import { message, Menu, Layout, Avatar, Dropdown, Button } from "antd";
import "antd/dist/antd.css";
import "./style.css";
import { Content, Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import Login from './pages/login';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  TagOutlined,
  TeamOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { NavRoutes } from './routes/routes';
import axios from "axios";


axios.interceptors.request.use(request => {
    const token = localStorage.getItem('jath');
    request.headers.jath =  token ? `${token}` : '';
    return request;
});

axios.interceptors.response.use(response => {
  return response;
}, (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('jath')
        return window.location.href = '/'
      } else {
        return error;
      }
  return Promise.reject(error);
});

const App = () => {
  const [authToken, setToken] = useState(localStorage.getItem('jath'));
  const [menuCollapsed, setMenuCollapsed] = useState(true);

  if(authToken == null) 
  {
    return (<Login setToken={setToken} />)
  }

  const profileMenu = (
    <Menu>
    <Menu.Item danger key="3" icon={<LogoutOutlined />} onClick={() => {setToken(null); localStorage.removeItem('jath')}}>
      Logout
    </Menu.Item>
  </Menu>
  );

  return (
    <Router>
      <Layout style={{backgroundColor: "transparent"}}>
        <Header  style={{
          height: '50px',
          backgroundColor: "rgb(253, 203, 92)",
          position: 'fixed',
          left: 0,
          top:0,
          width:"100%",
          zIndex:9999
        }}>
          
        <Layout>
          <Sider
              style={{
                position: 'fixed',
                right: 0,
                top:0,
                height: '50px',
                backgroundColor: "rgb(253, 203, 92)"
              }}
          >
            <Dropdown overlay={profileMenu} placement="bottomRight" arrow={{ pointAtCenter: true }}>
              <Avatar
              size={50}
              style={{
                position: 'fixed',
                right: 0,
                verticalAlign: 'middle',
              }}
              >{localStorage.getItem('un')}</Avatar>
            </Dropdown>
          </Sider>
        </Layout>
      </Header>
 
      <Sider
          width={menuCollapsed ? 50 : 200}
          style={{
            position: 'fixed',
            left: 0,
            top:'50px',
            bottom: 0,
            background: 'white',
          }}
        >
        <Button type="primary" onClick={() => {setMenuCollapsed(!menuCollapsed)}} style={{ marginBottom: 16, width: menuCollapsed ? 50 : 200 }}>
          {React.createElement(menuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
        </Button>
        <Menu mode="inline"
          defaultOpenKeys={['sub1']}
          style={{borderRight: 0 }}
          inlineCollapsed={menuCollapsed}
          >
          <Menu.Item icon={<TagOutlined />}><Link to="/">Tickets</Link></Menu.Item>
          <Menu.Item icon={<TeamOutlined />}><Link to="/userManagement">User Management</Link></Menu.Item>
        </Menu>
    </Sider>

      <Content style={{marginLeft: (menuCollapsed ? 60 : 220), marginRight: "25px", marginTop: "60px", marginBottom: "25px", padding: "5px"}}>
        <NavRoutes/>
      </Content>

   
  </Layout>
</Router>
  );
};

render(<App />, document.getElementById("root"));
