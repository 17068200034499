import React, { useState, useEffect, useRef } from "react";
import tickets from "../../dataLayer/tickets";
import { message, Input, Form, Modal, Select } from 'antd';
import { getSites, getStatus } from "../../utilities/ticketLookups";
const { Option } = Select;

export const AddTicket = (props) => {
    const [form] = Form.useForm();

    const handleCancel = () => {
        props.setShowModal(false);
    }

    const addTicket = async (values) => {
        console.log(props.modalData);
        if (props.modalData.length === 0) {
            await tickets.AddTicket(values);
        }
        else {
            // await users.updateUser(values, props.modalData.user_id);
        }
        props.refreshDataSet(!props.refreshDataProp);
        props.setShowModal(false);
    }

    return (
        <Modal title="Create new ticket" visible={props.isModalVisible} onOk={form.submit} onCancel={handleCancel} okText='Save'>
            <Form
                name="basic"
                form={form}
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                onFinish={addTicket}
                //  onFinishFailed={onFinishFailed}
                autoComplete="off"
                fields={[
                    { name: "title", value: props.modalData.title },
                    { name: "description", value: props.modalData.description },
                    { name: "site", value: props.modalData.site },
                    { name: "location", value: props.modalData.location },
                    { name: "status", value: 'New Ticket' },

                ]}
            >
                <Form.Item
                    label="Issue Summary"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: 'Please input a title!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description of issue"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Please input a description!',
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item
                    label="Site"
                    name="site"
                    rules={[
                        {
                            required: true,
                            message: 'Please input site!',
                        },
                    ]}
                >
                    <Select>
                        {getSites().map(s => <Option value={s.value}>{s.text}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Location"
                    name="location"
                    rules={[
                        {
                            required: true,
                            message: 'Please input location!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"
                    rules={[
                        {
                            required: true,
                            message: 'Please input status!',
                        },
                    ]}
                >
                    <Select>
                        {getStatus().map(s => <Option value={s.value}>{s.text}</Option>)}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default { AddTicket }