import React from 'react';
import axios from 'axios';

const apiUrl = 'http://tanavarduratan.hopto.org:8000/';

const getAllUsers = async() => {
    const response = await axios({
        method: 'get',
        url: apiUrl + 'api/v1/GetUsers'
    });

    return response.data;
}

const deleteUser = async(userId) => {
    const response = await axios({
        method: 'delete',
        url: apiUrl + 'api/v1/DeleteUser/' + userId
    });

    return response.data;
}

const addUser = async(userData) => {
    const response = await axios({
        method: 'post',
        url: apiUrl + 'api/v1/AddUser',
        data: userData
    });

    return response.data;
}

const updateUser = async(userData, userId) => {
    const response = await axios({
        method: 'post',
        url: apiUrl + 'api/v1/UpdateUser/' + userId,
        data: userData
    });

    return response.data;
}

const updateUserPref = async(prefId, PrefValue) => {
    const response = await axios({
        method: 'post',
        url: apiUrl + 'api/v1/SaveUserPref/' + prefId,
        data: PrefValue
    });

    return response.data;
}

const getUserPref = async(prefId) => {
    const response = await axios({
        method: 'get',
        url: apiUrl + 'api/v1/GetUserPrefs/' + prefId
    });

    return response.data;
}

export default {getAllUsers, deleteUser, addUser, updateUser, updateUserPref, getUserPref}